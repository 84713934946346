import React from 'react'
import Layout from '../../components/Layout'
import logoSmall from '../../img/logo_small.svg'
import { Link } from 'gatsby'

export default () => (
  <Layout navbarLogoHidden={true} smallFooter={true}>
    <section className="section">
    <div className="columns">
    <div className="column is-2"> </div>
      <div className="column is-8 contact-page-thanks-container" style={{padding: '4rem'}}>
          <div className="content has-text-centered">
            <img src={logoSmall} alt="logo" />
            <h3>Dziękuję za przesłanie formularza!</h3>
            <p style={{margin: '2rem 0rem 2rem 0rem'}}>Postaram się odpowiedzieć jak najszybciej. Zachęcam również do kontaktu telefonicznego.</p>
            <Link className="btn is-link" to="/">
                Strona główna
            </Link>
          </div>
          <div className="column is-2"> </div>
        </div>
      </div>
    </section>
  </Layout>
)
